<template>
  <LayoutViewWrapperNarrow class="content">
    <BaseH1 :text="'Zaloguj się'" :short="true" />
    <div class="wrapper-auth">
      <TextDescription
        :text="'Kontynuj przez'"
        class="description wrapper-auth__description"
      />
      <ButtonAuthGoogleSignIn
        :handleCredentialResponse="(res) => handleLoginGoogle(res.credential)"
      />
    </div>
    <div class="wrapper-email">
      <TextDescription :text="'Lub uzyj'" />
      <ButtonLink :to="{ name: 'LoginEmail' }" :label="'Adres e-mail'" />
    </div>
  </LayoutViewWrapperNarrow>
</template>

<script>
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";
import LayoutViewWrapperNarrow from "@/layouts/LayoutViewWrapperNarrow.vue";
import ButtonAuthGoogleSignIn from "@/components/UI/ButtonAuthGoogleSignIn.vue";
import useAuth from "@/composables/useAuth";

export default {
  components: {
    BaseH1,
    TextDescription,
    ButtonLink,
    LayoutViewWrapperNarrow,
    ButtonAuthGoogleSignIn,
  },

  setup() {
    const { handleLoginGoogle } = useAuth();

    return {
      handleLoginGoogle,
    };
  },
};
</script>

<style scoped>
.content {
  justify-content: flex-start;
  row-gap: 70px;
}
.wrapper-auth {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 55px;
}
.wrapper-email {
  width: 360px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
}

@media (max-width: 1200px) {
  .content {
    height: calc(
      100vh - var(--nav-mobile-height) - var(--header-default--height)
    );
    justify-content: center;
  }
}
</style>
